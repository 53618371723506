import { camelCase, forEach, isArray, isEmpty, isObject, isUndefined, map, mapValues, omit, reject, snakeCase, some, transform } from 'lodash-es'

import { matchPattern } from '@/Utilities/String'

export const flattenSelectValues = (object, key = 'value') => {
  if (!isObject(object) && !isArray(object)) {
    return object
  }

  if (isArray(object) && !isObject(object[0])) {
    return object
  }

  return mapValues(object, (value) => {
    if (isObject(value) && !isUndefined(value[key])) {
      return value[key]
    }

    if (isArray(value) && !isEmpty(value) && value[0][key]) {
      return map(value, (values) => {
        return values[key]
      })
    }

    return value
  })
}

export const formatKeys = (input, formatType) => {
  let formatTypes = {
    snake: snakeCase,
    camel: camelCase,
  }

  return transform(input, (accumulator, value, key, object) => {
    const formattedKey = isArray(object) ? key : formatTypes[formatType](key)

    accumulator[formattedKey] = isObject(value) ? formatKeys(value, formatType) : value
  })
}

export const toOptions = (array, ...args) => {
  return map(array, (item) => {
    let currentItem = {}

    forEach(args, (arg) => {
      let valueKey = arg
      let key = arg

      if (isArray(arg)) {
        valueKey = arg[0]
        key = arg[1]
      }

      if (key) {
        currentItem[key] = item[valueKey]
      } else {
        currentItem[valueKey] = item[valueKey]
      }
    })

    return currentItem
  })
}

export const defaultValue = (data = {}, type, fallback = null) => {
  if (!isEmpty(data[type])) {
    return {
      value: data[type].id,
      label: data[type].name,
    }
  }

  return fallback
}

export const rejectExclusions = (items, type = null, removeExcludeArray = true) => {
  items = reject(items, (item) => {
    return some(item.excludes, (exclude) => {
      return matchPattern(exclude, type)
    })
  })

  if (removeExcludeArray) {
    items = map(items, (item) => {
      return omit(item, 'excludes')
    })
  }

  return items
}
