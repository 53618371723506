import classNames from 'classnames'
import { isBoolean, map } from 'lodash-es'
import { rem } from 'polished'
import { Fragment, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useExpanded, usePagination, useTable } from 'react-table'
import styled from 'styled-components'
import useLocalStorage from 'use-local-storage'

import Select from '@/Components/form/Select'

const rowSpacing = '14px 24px'
const rowSpacingSmall = '10px 20px'

const TableWrapper = styled.div`
  background: #ffffff;
  border-radius: ${(props) => {
    return props.nested ? '0' : '5px'
  }};
  border: ${(props) => {
    return props.nested ? 'none' : '1px solid #E4E7EC'
  }};
`

const ResponsiveWrapper = styled.div`
  overflow-x: auto;
`

const StyledTable = styled.table`
  border-spacing: 0;
  min-width: ${(props) => {
    return props.tableMinWidth ? props.tableMinWidth : '750px'
  }};
  width: 100%;

  .highlight-row-mouse-indicator {
    transition: background-color 0.1s;

    &:hover {
      transition: background-color 0.1s;
      background-color: #F9FAFB;
    }
  }
`

const TableHead = styled.thead`
  background: #F9FAFB;
  border: none;
  color: #667085;
  font-size: ${rem(14)};
  text-align: left;

  th {
    border-bottom: 1px solid #E4E7EC;
    font-weight: 400;
    padding: ${rowSpacing};
  }
`

const TableBody = styled.tbody`
  border: none;
  text-align: left;

  td {
    border-top: 1px solid #E4E7EC;
    color: #667085;
    font-size: ${rem(14)};
    padding: ${(props) => {
    return props.compact ? rowSpacingSmall : rowSpacing
  }};

    &.collapsible-content {
      padding: 0;
    }
  }

  .center {
    text-align: center;
  }

  .highlight {
    background-color: #ffffff;
  }
`

const CollapsibleContent = styled.tr`
  display: none;

  &.visible {
    display: table-row;
  }
`

const Pagination = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: ${rem(14)};
  justify-content: space-between;
  user-select: none;
  border-top: 1px solid #E4E7EC;

  .left {
    min-width: 300px;
    text-align: left;

    .label {
      display: inline-block;
      margin-right: 15px;
    }
  }

  .right {
    align-self: center;
    text-align: right;
    justify-content: flex-end;
  }

  .left, .right {
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 10px 20px;
  }
`

const PageNumber = styled.div`
  display: inline-block;
  margin-right: 40px;
`

const NavigationButton = styled.div`
  cursor: ${(props) => {
    return props.disabled ? 'default' : 'pointer'
  }};
  display: inline-block;
  margin: 6px 10px;
  opacity: ${(props) => {
    return props.disabled ? '0.4 !important' : 1
  }};
  padding: 2px 6px;
  transition: all 0.1s;

  &:hover {
    opacity: 0.6;
  }
`

function Table(props) {
  const {
    columns,
    data,
    searchTypes,
    searchTerm,
    selectedTab,
    getTableData,
    shouldFirstLoad,
    filterList,
    testId,
  } = props

  const [defaultPageSize, setDefaultPageSize] = useLocalStorage('table.pageSize', {
    value: '15',
    label: '15',
  })
  // const [searchTerm, setSearchTerm] = useState({})
  const [selectedFilter] = useState(map(filterList, 'key'))
  // const [selectedTab] = useState({})
  const [firstLoad, setFirstLoad] = useState(isBoolean(shouldFirstLoad) ? shouldFirstLoad : true)

  const {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    pageCount,
    pageOptions,
    prepareRow,
    previousPage,
    rows,
    setPageSize,
    state: { pageIndex, pageSize }, // eslint-disable-line
  } = useTable(
    {
      columns,
      data: data.data,
      manualPagination: true,
      pageCount: data.last_page || data.lastPage,
      initialState: {
        pageIndex: 0,
        pageSize: defaultPageSize.value,
      },
    },
    useExpanded,
    usePagination,
  )

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)

      // if (searchTypes) {
      //   setSearchTerm({
      //     ...searchTerm,
      //     type: head(searchTypes).value,
      //   })
      // }
    }

    if (getTableData) {
      getTableData({
        pageIndex: pageIndex,
        pageSize: pageSize,
        filters: {
          search: searchTerm,
          tab: selectedTab,
          type: selectedFilter,
        },
      })
    }
  }, [
    getTableData,
    pageIndex,
    pageSize,
    searchTerm,
    selectedTab,
    selectedFilter,
    searchTypes,
    // setSearchTerm,
  ])

  return (
    <TableWrapper
      data-cy={testId}
      data-testid={testId}
      data-has-error={props.hasError || false}
      nested={props.nested || false}
      className="shadow"
    >
      {props?.header}
      <ResponsiveWrapper>
        <StyledTable {...getTableProps()} tableMinWidth={props.tableMinWidth}>
          <TableHead>
            {map(headerGroups, (headerGroup) => {
              return (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {map(headerGroup.headers, (column, columnIndex) => {
                    return (
                      <Fragment key={columnIndex}>
                        {props.collapsibleContent && columnIndex === 0 && <th style={{ width: '50px' }}></th>}

                        <th
                          {...column.getHeaderProps()}
                          style={{ width: column.width || 'initial' }}
                        >
                          {column.render('Header')}
                        </th>
                      </Fragment>
                    )
                  })}
                </tr>
              )
            })}
          </TableHead>

          <TableBody
            {...getTableBodyProps()}
            compact={props.compact}
          >
            {
              rows.length ?
                map(rows, (row, rowIndex) => {
                  prepareRow(row)

                  return (
                    <Fragment key={rowIndex}>
                      <tr
                        {...row.getRowProps()}
                        className={classNames({ highlight: row.isExpanded }, 'highlight-row-mouse-indicator')}
                      >
                        {map(row.cells, (cell) => {
                          return (
                            <td
                              {...cell.getCellProps([{ style: cell.column.style }])}
                            >
                              {props.loading || firstLoad ? <Skeleton /> : cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>

                      {
                        (props.loading && row.isExpanded) ?
                          <tr>
                            <td colSpan={props.collapsibleContent ? (columns.length + 1) : columns.length}>
                              <Skeleton />
                            </td>
                          </tr> :
                          <CollapsibleContent
                            className={classNames({ visible: row.isExpanded })}
                          >
                            <td
                              className="collapsible-content"
                              colSpan={props.collapsibleContent ? (columns.length + 1) : columns.length}
                            >
                              {row.original.renderRowSubComponent}
                            </td>
                          </CollapsibleContent>
                      }
                    </Fragment>
                  )
                }) :
                (
                  <tr>
                    <td colSpan={props.collapsibleContent ? (columns.length + 1) : columns.length} className="center">
                      {props.loading || firstLoad ? <Skeleton /> : 'No data to display.'}
                    </td>
                  </tr>
                )
            }
          </TableBody>
        </StyledTable>
      </ResponsiveWrapper>

      {!props.hidePagination && (
        <Pagination>
          <div className="left">
            <div className="label">
              Rows per page
            </div>

            <Select
              isSearchable={false}
              small
              display="inline-block"
              width="auto"
              defaultValue={[defaultPageSize]}
              options={[
                {
                  value: '5',
                  label: '5',
                },
                {
                  value: '15',
                  label: '15',
                },
                {
                  value: '25',
                  label: '25',
                },
                {
                  value: '50',
                  label: '50',
                },
                {
                  value: '100',
                  label: '100',
                },
              ]}
              afterChange={(option) => {
                if (option?.value) {
                  setDefaultPageSize(option)
                  setPageSize(option.value)
                }
              }}
            />
          </div>

          <div className="right">
            <PageNumber>
              {
                pageOptions.length !== 0 ? (
                  <>
                    Page {pageIndex + 1} of {pageOptions.length}
                  </>
                ) : (
                  <>
                    Page 1 of 1
                  </>
                )
              }
            </PageNumber>

            <NavigationButton onClick={() => {
              return gotoPage(0)
            }} disabled={!canPreviousPage}>
              <i className="far fa-chevron-double-left"></i>
            </NavigationButton>

            <NavigationButton onClick={() => {
              return previousPage()
            }} disabled={!canPreviousPage}>
              <i className="far fa-chevron-left"></i>
            </NavigationButton>

            <NavigationButton onClick={() => {
              return nextPage()
            }} disabled={!canNextPage}>
              <i className="far fa-chevron-right"></i>
            </NavigationButton>

            <NavigationButton onClick={() => {
              return gotoPage(pageCount - 1)
            }} disabled={!canNextPage}>
              <i className="far fa-chevron-double-right"></i>
            </NavigationButton>
          </div>
        </Pagination>
      )}
    </TableWrapper>
  )
}

export default Table
